import React, { useState, Fragment } from 'react';
import {
  Col,
  Row,
  Button
} from "reactstrap";

const TeamMember = (props) => {
  return(
    <React.Fragment>
      <Row>
        <Col sm="12" md={{ size: 6, offset: 3 }}>
          <div className="team-player">
            <img
              alt="..."
              className="rounded-circle img-fluid img-raised"
              src={props.img}
              src={require("assets/img/team/pradeep.png")}
            ></img>
            <h4 className="title">{props.name}</h4>
            <p className="category text-info">Founding Director</p>
            <p className="description">
              {props.about}
            </p>
            <Button
              className="btn-icon btn-round"
              color="info"
              href={props.linkedin}
              target="_blank"
              // onClick={(e) => e.preventDefault()}
              >
              <i className="fab fa-linkedin"></i>
            </Button>
          </div>    
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default TeamMember
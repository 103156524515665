import React from 'react';
import {Container} from 'reactstrap'
import TestImg from 'views/Solutions/test';

const LandingPage = () => {
  return(
    <div className="section">
      <Container className="text-center">
        <h2 className="title">Let's Introduce Ourselves</h2>
        <p>
        As the global business moving to new norms, we at DIGIBLOOM SOLUTIONS P LTD help the industries
          with the unique products and solutions to enhance Productivity using the IOT &amp; IIOT 4.0 , To bring in
          automation on all type of industries with the RADAR Based solutions, RFID based solutions &amp; healthcare
          solutions
        </p>
        <p>
        Atmanirbhar Bharat, which translates to &#39;self-reliant India&#39; or &#39;self-sufficient India&#39;, is a policy formulated
          by Prime Minister of India Narendra Modi of making India &quot;a bigger and more important part of the
          global economy&quot;, pursuing policies that are efficient, competitive and resilient, and being self-sustaining
          and self-generating. Digibloom Solutions ensures all the India and Global Centric Solutions are designed
          In India , Manufactured in India for Indian and Global Market.
        </p>
        <p>
          DSPL incorporated in 2020 , DSPL is backed by the management with over 200 + years of collective
          experience in the area of Industrial IOT and IOT and embedded product development from hardware
          design , embedded software development including micro OS, Application development on mobile or
          web and intensive database management and Artificial intelligence.
        </p>
        <br></br>
        <br></br>
        <img
          alt="..."
          src={require("assets/img/mii.png")}
        ></img>
        <h4 className="title">The Team</h4>
        <p>
          Believe in Agile way of running the company Business
          Believe in scalable and empowered Micro Organization / Partners
          Strongly believe in collaboration with Big Players as ODM or OEM Partners
        </p>
        <TestImg />
      </Container>
    </div>
  )
}

export default LandingPage
import DefaultFooter from 'components/Footers/DefaultFooter';
import SolidNavbar from 'components/Navbars/SolidNavbar';
import IndexNavbar from 'components/Navbars/IndexNavbar';
import React from 'react';
import ProductEnquiry from 'views/Forms/ProductInfo';
import TeamList from 'views/TeamList/Team';
import ProductsItem1 from './ProductItem1';
import ProductList from './ProductList';

const Products = () => {
  return(
    <React.Fragment>
      <SolidNavbar />
      {/* <ProductsItem1 /> */}
      <ProductList />
      <hr></hr>
      {/* <TeamList /> */}
      <ProductEnquiry />
      <DefaultFooter />
    </React.Fragment>
  )
}

export default Products
import React from 'react';
import {
  Card, CardImg, CardText, CardBody, Container, Col
} from 'reactstrap';
import Row from 'reactstrap/lib/Row';

const AboutUsPortFolio = (props) => {
  return (
    <Container>
      <div>
      <Row>
        <Col>
        <Card>
        <CardImg top width="100%" src={require("assets/img/about-us/7.png")} alt="Card image cap" />
        <CardBody>
          {/* <CardTitle tag="h5" className="mb-2 text-muted">Heading</CardTitle> */}
          {/* <CardSubtitle tag="h6" className="mb-2 text-muted">Card subtitle</CardSubtitle> */}
          <CardText className="mb-2 text-muted">
            By designing, developing and manufacturing AI-powered security solutions,
            our mission is to deliver innovative products that help keep people and 
            communities safe. From security cameras to video analytics, access control 
            solutions and cloud services, our complete solutions have been installed at 
            thousands of customer sites around the world, empowering you to proactively 
            protect what matters most.
          </CardText>
        </CardBody>
      </Card>
        </Col>
        <Col>
          <Card>
          <CardImg top width="100%" src={require("assets/img/about-us/8.png")} alt="Card image cap" />
          <CardBody>
            {/* <CardTitle tag="h5" className="mb-2 text-muted">Heading</CardTitle> */}
            {/* <CardSubtitle tag="h6" className="mb-2 text-muted">Card subtitle</CardSubtitle> */}
            <CardText className="mb-2 text-muted">
            We ensure highest level of satisfaction and certainty on all our solutions through professional
            services by brining in comprehensive industry expertise in various technologies and domains and
            ensure we do all the work in india and maintaining global standard
            </CardText>
          </CardBody>
        </Card>
          </Col>
          <Col>
          <Card>
          <CardImg top width="100%" src={require("assets/img/about-us/9.png")} alt="Card image cap" />
          <CardBody>
            {/* <CardTitle tag="h5" className="mb-2 text-muted">Heading</CardTitle> */}
            {/* <CardSubtitle tag="h6" className="mb-2 text-muted">Card subtitle</CardSubtitle> */}
            <CardText className="mb-2 text-muted">
            By designing and developing AI powered Coved solutions , our mission is to deliver innovative low 
            cost made in india solutions for our community
            </CardText>
          </CardBody>
        </Card>
          </Col>
      </Row>
    </div>
    <div>
        <Row>
          <Col>
          <Card>
          <CardImg top width="100%" src={require("assets/img/about-us/10.png")} alt="Card image cap" />
          <CardBody>
            {/* <CardTitle tag="h5" className="mb-2 text-muted">Heading</CardTitle> */}
            {/* <CardSubtitle tag="h6" className="mb-2 text-muted">Card subtitle</CardSubtitle> */}
            <CardText className="mb-2 text-muted">
            By designing and developing AI powered products to support Industry automation under Industrial 
            IOT 4.0 and deliver to our customers a cost effective simple solution to improve their manufacturing 
            productivity
            </CardText>
          </CardBody>
        </Card>
          </Col>
          <Col>
            <Card>
            <CardImg top width="100%" src={require("assets/img/about-us/11.png")} alt="Card image cap" />
            <CardBody>
              {/* <CardTitle tag="h5" className="mb-2 text-muted">Headingn</CardTitle> */}
              {/* <CardSubtitle tag="h6" className="mb-2 text-muted">Card subtitle</CardSubtitle> */}
              <CardText className="mb-2 text-muted">
              To our customer we ensure we deliver simple solutions to their complex problem at a competitive cost 
              and improving the customer satisfaction by every project we engage with them
              </CardText>
            </CardBody>
          </Card>
            </Col>
            <Col>
            </Col>
        </Row>
      </div>
    </Container>
  );
};

export default AboutUsPortFolio;
import React from 'react';
import { Widget, addResponseMessage, renderCustomComponent } from 'react-chat-widget';
import logo from '../assets/img/logo.png'
import AWS from 'aws-sdk'

import 'react-chat-widget/lib/styles.css';
import chatCard from './ProductCard';

// Initialize the Amazon Cognito credentials provider
AWS.config.region = 'ap-southeast-1'; // Region
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: "ap-southeast-1:8ff90a5d-1d7c-4d66-99a7-7410dc859f29",
});

const lex = new AWS.LexRuntime()


const Chatbot = () => {

  const handleNewUserMessage = (newMessage) => {
    console.log(`New message incoming! ${newMessage}`);
    // Now send the message throught the backend API
    // addResponseMessage(newMessage)
    lexChat(newMessage)
  };

  const lexChat = (message) => {
    // Lex params
    let params = {
      botName: 'dsplChatBot',
      inputText: message,
      botAlias: '$LATEST',
      userId: "123"
    };

    //AWS Lex connecting function
    lex.postText(params, (err, data) => {
      if (err) {
        console.log(err)
      } 
      if (data) {
        try {
          // const d = JSON.parse(data.message)
          // console.log(d)
          addResponseMessage(data.message)
          // console.log(data.message)
          console.log(data)
          if(data.responseCard) {
            const cardData = data.responseCard.genericAttachments[0]
            const img = cardData.imageUrl
            const title = cardData.title
            const subTitle = cardData.subTitle
            const buttons = cardData.buttons
            console.log(img)
            renderCustomComponent(
              chatCard,
              {
                img,
                title,
                subTitle,
                buttons,
                lexChat
              },
              true
            )
          }
        } catch (error) {
          addResponseMessage("Oops !!.. Something Went Wrong, Please try after sometimr")
          console.log(error)
        }
      }
    })

  }

  return ( 
    <>
      <Widget
        handleNewUserMessage={handleNewUserMessage}
        profileAvatar={logo}
        title="Digibot"
        subtitle="Digibloom Virtual Assistant"
      />
    </>
  );
}

export default Chatbot;
import React from 'react';
import {
  Card, CardImg, CardText, CardBody,
  CardTitle, CardSubtitle, Button, Container, Col, Row
} from 'reactstrap';

const ProductCard = (props) => {
  return ( 
    <div style={{width: 250}}>
      <Card>
        {/* <CardImg top width="100%" src="https://digibloomsolutions.com/static/media/nirmal-500.facaa2a4.png" alt="Card image cap" /> */}
        <CardImg top width="100%" src={props.img} alt="Card image cap" />
        <CardBody>
          <CardTitle tag="h5">{props.title}</CardTitle>
            <CardSubtitle tag="h6" className="mb-2 text-muted">{props.subTitle}</CardSubtitle>
            {/* <CardText>Continuously measure distance,.</CardText> */}
            <Row>
              { props.buttons.map((item) => {
                return(
                  <Col>
                    <Button onClick={() => props.lexChat(item.value)}>{item.text}</Button>
                  </Col>
                )
              })}
            </Row>
        </CardBody>
      </Card>
    </div>
  );
}

export default ProductCard;
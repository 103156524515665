import SolidNavbar from 'components/Navbars/SolidNavbar';
import React from 'react';
import { 
  Jumbotron, 
  Container, 
  ListGroup,
  ListGroupItem
} from 'reactstrap';


const Downloads = () => {
  return ( 
    <>
      <SolidNavbar />
      <br></br>
      <br></br>
      <br></br>
      <Jumbotron fluid>
        <Container fluid>
          <h1 className="display-3">Downloads</h1>
          <p className="lead">Downloads available for Digibloom Customers.</p>
        </Container>
      </Jumbotron>
      <Container>
        <h3>Product Downloads</h3>
        <ListGroup flush>
          <ListGroupItem target="_blank" tag="a" href="https://brochure-dspl.s3.ap-south-1.amazonaws.com/brochure+for+hand+sanitiser+1000ML.pdf">Nirmal 1000 Hand Sanitiser Brochure</ListGroupItem>
          <ListGroupItem target="_blank" tag="a" href="https://brochure-dspl.s3.ap-south-1.amazonaws.com/brochure+for+hand+sanitiser+2000ML.pdf">Nirmal 2000 Hand Sanitiser Brochure</ListGroupItem>
          <ListGroupItem target="_blank" tag="a" href="https://brochure-dspl.s3.ap-south-1.amazonaws.com/brochure+for+hand+sanitiser+500+ML.pdf">Nirmal 500 Hand Sanitiser Brochure</ListGroupItem>
        </ListGroup>
      </Container>
      <br></br>
      <br></br>
      <br></br>
      <Container>
        <h3>Solutions Downloads</h3>
        <ListGroup flush>
          <ListGroupItem target="_blank" tag="a" href="https://brochure-dspl.s3.ap-south-1.amazonaws.com/CROWD+Management+System+Brochure.pdf">Crowd Management System</ListGroupItem>
        </ListGroup>
      </Container>
      {/* <DarkFooter /> */}
    </>
  );
}

export default Downloads;
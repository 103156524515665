import React from "react";

// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  ListGroup, 
  ListGroupItem,
} from "reactstrap";

// core components
import SolidNavbar from "components/Navbars/SolidNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import TeamMember from "views/TeamMembersCard/TeamMember";
import JoinUsForm from "./JoinUsForm";
import AboutUsPortFolio from "./AboutUsPortFolio";
import TeamList from "views/TeamList/Team";

const AboutUs = () => {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <SolidNavbar />
      <div className="wrapper">
        <LandingPageHeader />
        <div className="section section-about-us">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">Who we are?</h2>
                {/* <h5 className="description">
                  According to the National Oceanic and Atmospheric
                  Administration, Ted, Scambos, NSIDClead scentist, puts the
                  potentially record low maximum sea ice extent tihs year down
                  to low ice extent in the Pacific and a late drop in ice extent
                  in the Barents Sea.
                </h5> */}
              </Col>
            </Row>
            <div className="separator separator-primary"></div>
            <div className="section-story-overview">
              <Row>
                <Col md="6">
                  <div
                    className="image-container image-left"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/about-us/14.png") + ")",
                    }}
                  >
                    <p className="blockquote blockquote-info">
                      "The ones who are crazy enough to think they can change the world, are the ones that do." 
                      <br></br>
                      <br></br>
                      <small>- Anonymous</small>
                    </p>
                  </div>
                  <div
                    className="image-container"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/about-us/12.png") + ")",
                    }}
                  ></div>
                </Col>
                <Col md="5">
                  <div
                    className="image-container image-right"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/about-us/13.png") + ")",
                    }}
                  ></div>
                  <h3>
                    Let's Talk Digibloom
                  </h3>
                  <p>
                    Digibloom Solutions, a pioneering start up with products and solutions 
                    in the area of IOT, &amp; IIOT solutions with multiple products for various
                    domains like Surveillance, Digital Signage, Consumer 
                    products based on IOT for offices or residences and industry
                  </p>
                  <ul>
                    <li>
                      <p>
                      Group of Experienced and seasoned Software Professionals joined together 
                      to build product / Solution in IOT Space and connect homes and communities 
                      and industrial IOT
                      </p>
                    </li>
                    <li>
                      <p>
                        Integrated simple solution for all our customers
                      </p>
                    </li>
                    <li>
                      <p>
                        Believe in Agile way of running the company &amp; Business
                      </p>
                    </li>
                    <li>
                      <p>
                        Strongly believe in collaboration
                      </p>
                    </li> 
                    <li>
                      <p>
                        Self Financing Model for Growth
                      </p>
                    </li>
                    <li>
                      <p>
                        A Business with Social Commitment
                      </p>
                    </li>
                    <li>
                      <p>
                        Create your Own North Star – A true Success story
                      </p>
                    </li>
                  </ul>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <div
        className="section section-download"
        data-background-color="black"
        id="download-section"
        > 
          <Container>
            <Row className="justify-content-md-center">
              <h3 className="title">Digibloom Solutions Private Ltd</h3>
              <Col className="text-center" lg="8" md="12">
                <h5 className="description">
                  Digibloom Solutions is a specialist in product engineering , providing idea to complete
                  solutions to various customers across Internet of Things Domain, Industries for their
                  Automation 4.0 , Surveilance and security domain, Digital signage etc. We solve complex
                  business problems into simplified solutions delivered at lower cost and highst satisfaction
                  using starte of the art technology design , Modular software design, seamlessly integratable
                  with other similar solutions
                </h5>
                <h5 className="description">
                We help our clients or build their success on us by working with us in the area of Industry
                Automation, IOT, Security &amp; surveillance , Digital Signage and professional services
                </h5>
              </Col>
              <Col className="text-center" lg="12" md="12">
              <h3>What we do?</h3>
              <AboutUsPortFolio />
              </Col>
            </Row>
            
          </Container>
        </div>
        <div className="section section-team text-center">
          <Container>
            <h2 className="title">Here is our team</h2>
            <div className="team">
              <Row>
                <TeamList />  
              </Row>
            </div>
          </Container>
        </div>
        <JoinUsForm />
        <DefaultFooter />
      </div>
    </>
  );
}

export default AboutUs;

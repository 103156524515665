import React from "react";
// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";
import { useParams, withRouter } from 'react-router-dom'
import data from '../../data/products.json'
import SolidNavbar from "components/Navbars/SolidNavbar";
import DarkFooter from "components/Footers/DarkFooter";
import DefaultFooter from "components/Footers/DefaultFooter";

// core components




//List Item Generator
const listItemgenerator = (listArray) => {
  let finishedArray = []
  listArray.map((item) => {
    // console.log(item)
    finishedArray.push(<li key={item} >{item}</li>)
  })
  return finishedArray
}

function ProductSpec(props) {
  let { slug } = useParams()
  let productInformation = data.filter((itemdata) => {
    if (itemdata.id == slug) {
      return itemdata
    } else {
      // props.history.push("/");
    }
  })

  console.log(productInformation)
  return (
  <>
    <SolidNavbar />
    <div className="section section-nucleo-icons">
    <Container>
      <Row>
        <Col lg="6" md="12">
          <h2 className="title">{ productInformation[0].name }</h2>
          <h5 className="description">
            { productInformation[0].description }
          </h5>
          {/* <h5 className="description">
          We help our clients or build their success on us by working with us in the area of Industry
          Automation, IOT, Security &amp; surveillance , Digital Signage and professional services
          </h5> */}
          {/* <Button
          className="btn-round mr-1"
          color="info"
          href="/products"
          size="lg"
          target="_blank"
          >
          View All Products
          </Button>
          <Button
          className="btn-round"
          color="info"
          href="https://nucleoapp.com/?ref=1712"
          outline
          size="lg"
          target="_blank"
          >
          Specifications
          </Button> */}
        </Col>
        <Col lg="6" md="12">
        <div className="hero-images-container-3">
          <img
            alt="..."
            src={require(`assets/img/products/${productInformation[0].image}.png`)}
          ></img>
          </div>
        </Col>
      </Row>
    </Container> 
    <Container>
      <br></br>
      <hr></hr>
      <Row>
        <Col lg="6" md="12">
          <h3 className="title">Features</h3>
        </Col>
        <Col lg="6" md="12">
          <br></br>
          <ul>
            {listItemgenerator(productInformation[0].features)}
          </ul>
        </Col>
      </Row>
      <br></br>
    </Container>  
    <Container>
        <hr></hr>
        <br></br>
        <Row>
          <Col lg="6" md="12">
            <h3 className="title">Advantages</h3>
          </Col>
          <Col lg="6" md="12">
            <br></br>
            <ul>
              {listItemgenerator(productInformation[0].advantages)}
            </ul>
          </Col>
        </Row>
        <br></br>
      </Container>
      <Container>
          <hr></hr>  
          <br></br>
          <Row>
            <Col lg="6" md="12">
              <h3 className="title">Technical Specification</h3>
            </Col>
            <Col lg="6" md="12">
              <br></br>
              <ul>
                {listItemgenerator(productInformation[0].techspec)}
              </ul>
            </Col>
          </Row>
          <br></br>
        </Container>
    </div>
    <DefaultFooter />
  </>
  );
}

export default withRouter(ProductSpec);

import React from 'react';
import ProductItem1 from './ProductItem1'
import ProductItem2 from './ProductItem2'
// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";
//Product Data
import data from '../../data/products.json'

const ProductList = () => {
  data.reverse() // Reversal of order of products
  return ( 
    <>
      <div className="section ">
        <Container>
          <Container>
            <h2 className="text-center title">Our Products</h2>
            <hr></hr>
          </Container>
        </Container>
        {/* <ProductItem1 /> */}
        {/* <ProductItem2 /> */}
      </div>
      {
        data.map((item) => {
          return (
            <ProductItem1 
              description={item.description}
              name={item.name}
              image={item.image}
              features={item.features}
              id={item.id}
            />
          );
        })
      }
    </>
  );
}

export default ProductList;
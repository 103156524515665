import React from "react";
// reactstrap components
import { Container, Row, Col } from "reactstrap";
import Map from "./Map";

// core components

function ContactCard() {
  return (
    <>
      <div className="section ">
        <Container>
          <Container>
            <h2 className="text-center title">Contact Us</h2>
            <hr></hr>
          </Container>
          <br></br>
          <br></br>
          <Row>
            <Col lg="6" md="12">
              <h2 className="title">DIGIBLOOM SOLUTIONS PRIVATE LIMITED</h2>
              <h4>Email: info@digibloom.com</h4>
              <h5>Address:</h5>
              <h5>1336/2, D Block AECS Layout, Kundalahalli</h5>
              <h5>Bangalore 560037</h5>
              <h5>Karnataka, India</h5>
              <br></br>
              <h5>Phone: +91 9900480018</h5>
            </Col>
            <Col lg="6" md="12">
            <div className="hero-images-container-1">
                {/* <img
                  alt="..."
                  src={require("assets/img/products/nirmal-500.png")}
                ></img> */}
                <Map style={{width: '40vw', height: '40vh'}}/>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default ContactCard;

import React from "react";
// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

// core components

function ProductItem1(props) {
  return (
    <>
      <div className="section ">
        <Container>
          <Row>
            <Col lg="6" md="12" >
              <h2 className="title">{props.name}</h2>
              <h5 className="description">
                <b>{props.description}</b>
              </h5>
              <ul>
                {
                  props.features.map((feature) => {
                    return <li className="description"><h6 >{feature}</h6></li>
                  })
                }
              </ul>
              <Button
                className="btn-round mr-1"
                color="info"
                href="https://www.indiamart.com/company/21835493/"
                size="lg"
                target="_blank"
              >
                Buy Now
              </Button>
              <Button
                className="btn-round"
                color="info"
                href={`/spec/${props.id}`}
                outline
                size="lg"
              >
                Specifications
              </Button>
            </Col>
            <Col lg="6" md="12">
            <div className="hero-images-container-1">
                <img
                  alt="..."
                  // src={require(`${props.image}`)}
                  src={require("assets/img/products/" + `${props.image}` + ".png")}
                  // src={"assets/img/products/nirmal-500.png"}
                ></img>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default ProductItem1;

import React from "react";

// reactstrap components
import {
  Button,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

// core components
import SolidNavbar from "components/Navbars/SolidNavbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import Solutions from "views/Solutions/Solutions";
import IndexNavbar from "components/Navbars/IndexNavbar";

function TestImg() {
  const [pills, setPills] = React.useState("2");
  React.useEffect(() => {
  document.body.classList.add("profile-page");
  document.body.classList.add("sidebar-collapse");
  document.documentElement.classList.remove("nav-open");
  window.scrollTo(0, 0);
  document.body.scrollTop = 0;
  return function cleanup() {
    document.body.classList.remove("profile-page");
    document.body.classList.remove("sidebar-collapse");
  };
  }, []);
  return (
  <>
    <div className="section">
      <Container>
      <Row>
        <Col className="ml-auto mr-auto" md="6">
        <h4 className="title text-center">Our Portfolio</h4>
        <div className="nav-align-center">
        </div>
        </Col>
        <div className="gallery" TestImg>
        <div tabId="pills3">
          <Col className="ml-auto mr-auto" md="10">
          <Row className="collections">
            <Col md="6">
            <a href="/products">
            <img
              alt="..."
              className="img-raised"
              src={require("assets/img/landing-page/1.png")}
            ></img>
            </a>
            <a href="/crowd-mgmt">
            <img
              alt="..."
              className="img-raised"
              src={require("assets/img/landing-page/2.png")}
            ></img>
            </a>
            </Col>
            <Col md="6">
            <a href="/iiot">
            <img
              alt="..."
              className="img-raised"
              src={require("assets/img/landing-page/3.png")}
            ></img>
            </a>
            <a href="/rfid">
            <img
              alt="..."
              className="img-raised"
              src={require("assets/img/landing-page/4.png")}
            ></img>
            </a>
            </Col>
          </Row>
          </Col>
        </div>
        </div>
      </Row>
      </Container>
    </div>
  </>
  );
}

export default TestImg;

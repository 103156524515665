import DefaultFooter from 'components/Footers/DefaultFooter';
import SolidNavbar from 'components/Navbars/SolidNavbar';
import React from 'react';
import ProductEnquiry from 'views/Forms/ProductInfo';
import ContactCard from './ContactCard';

const ContactUs = () => {
  return(
    <React.Fragment>
      <SolidNavbar />
      <ContactCard />
      <hr></hr>
      <ProductEnquiry />
      <DefaultFooter />
    </React.Fragment>
  )
}

export default ContactUs
/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";

// core components

function DefaultFooter() {
  return (
    <>
      <footer className="footer footer-default">
        <Container>
          <nav>
            <ul>
              <li>
                <a
                  href="about-us"
                >
                  About Us
                </a>
              </li>
              <li>
                <a
                  href="/privacy"
                >
                  Privacy Policy
                </a>
              </li>
            </ul>
          </nav>
          <div className="copyright" id="copyright">
            © {new Date().getFullYear()} {"  "}
            <a
              href="/index"
            >
              Digibloom Solutions Pvt Ltd
            </a>
            . Theme from{" "}
            <a
              href="https://www.creative-tim.com"
            >
              Creative Tim
            </a>
            .
          </div>
        </Container>
      </footer>
    </>
  );
}

export default DefaultFooter;

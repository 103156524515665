import DarkFooter from 'components/Footers/DarkFooter';
import SolidNavbar from 'components/Navbars/SolidNavbar';
import React from 'react';
import PrivacyContent from './PrivacyContent';

const Privacy = () => {
  return(
    <React.Fragment>
      <SolidNavbar />
      <PrivacyContent />
      <DarkFooter />
    </React.Fragment>
  )
} 

export default Privacy
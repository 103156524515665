import Index from "views/Index.js";
import LoginPage from "views/examples/LoginPage.js";
import ProfilePage from "views/examples/ProfilePage.js";
import Products from "views/Products/Products";
import Privacy from "views/PrivacyCopyright/Privacy";
import ContactUs from "views/ContactUs";
import Solution from "views/Solution/Solution";
import Downloads from "views/Downloads/Downloads";
import ProductSpec from './views/ProductSpec/ProductSpec'
import AboutUs from "views/AboutUs";
import Iiot from "views/Iiot/Iiot";
import Rfid from "views/rfid/rfid";

const websiteRoutes = [
  {
    path: "/index",
    name: "Index",
    component: Index
  },
  {
    path: "/products",
    name: "Product",
    component: Products
  },
  {
    path: "/about-us",
    name: "ABout Us",
    component: AboutUs
  },
  {
    path: "/products",
    name: "Product",
    component: Products
  },
  {
    path: "/profile-page",
    name: "Profile Page",
    component: ProfilePage
  },
  {
    path: "/login-page",
    name: "Login Page",
    component: LoginPage
  },
  {
    path: "/products",
    name: "Product",
    component: Products
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: Privacy
  },
  {
    path: "/crowd-mgmt",
    name: "Solutions",
    component: Solution
  },
  {
    path: "/contact-us",
    name: "Contact Us",
    component: ContactUs
  },
  {
    path: "/spec/:slug",
    name: "Spec",
    component: ProductSpec
  },
  {
    path: "/downloads",
    name: "Downloads",
    component: Downloads
  },
  {
    path: "/iiot",
    name: "Iiot",
    component: Iiot
  },
  {
    path: "/rfid",
    name: "Rfid",
    component: Rfid
  },
  {
    path: "/test",
    name: "test",
    component: Rfid
  },
]

export default websiteRoutes



import React from "react";

// reactstrap components
import {
  Button,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

// core components
import SolidNavbar from "components/Navbars/SolidNavbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import Solutions from "views/Solutions/Solutions";
import IndexNavbar from "components/Navbars/IndexNavbar";
import Home from "./Home";
import Home2 from "./Home2";
import Example from "views/Card/Card";

function Solution() {
  const [pills, setPills] = React.useState("2");
  React.useEffect(() => {
  document.body.classList.add("profile-page");
  document.body.classList.add("sidebar-collapse");
  document.documentElement.classList.remove("nav-open");
  window.scrollTo(0, 0);
  document.body.scrollTop = 0;
  return function cleanup() {
    document.body.classList.remove("profile-page");
    document.body.classList.remove("sidebar-collapse");
  };
  }, []);
  return (
  <>
    <div className="section"> 
      <Container >
      <SolidNavbar />
      <h3 className="title ">CROWD MANAGEMENT SOLUTION </h3>
      <h5 >
              In the crisis of COVID 19 contagion, the
        need of the hour is to maintain a SOCIAL
        DISTANCE of 1 meter. Maintaining social
        distance become very difficult when
        people are in public places like Mall,
        Theatres, event venues, Bus stand ,
        Railway stations , Airport etc. It is very
        unusual that PEOPLE follows the norm
        for social distancing and hence there is
        huge RISK of spread of the virus among
        the population.
      </h5>
      <Home />
      <Row>
        <Col className="ml-auto mr-auto" md="12">
        <h4 className="title text-center">Our Solution</h4>
        <div className="nav-align-center">
          <Example />
        </div>
        </Col>
      </Row>
      <Home2 />
      </Container>
    </div>
    <DefaultFooter />
  </>
  );
}

export default Solution;

import React from "react";
// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

// core components

function Home() {
  return (
    <>
      <div className="section section-nucleo-icons">
        <Container>
          <Row>
            <Col lg="6" md="12">
              <h4 className="title">mmWave for motion detection and people counting</h4>
              <h5>
              The People Counting and Tracking Reference
              Design Using mmWave Radar Sensor shows the
              use of TI’s mmWave sensors for indoor and outdoor
              people-counting applications. This reference design
              uses the IWR6843 device, which includes both RF/
              analog and digital processing cores to run onboard
              algorithms enabling a single-chip people-counting
              system that can detect and track people up to
              14 m away.
              </h5>
              <h5 >
              The sensor signals can transmit through different
              types of materials such as drywall, plywood and
              plastic, enabling unique installation options that can
              be hidden behind walls and other objects to avert
              system damage or maintain a clean industrial design.
              </h5>
              <Button
                className="btn-round mr-1"
                color="info"
                href="/products"
                size="lg"

              >
                View All Products
              </Button>
              {/* <Button
                className="btn-round"
                color="info"
                href=""
                outline
                size="lg"
              >
                Specifications
              </Button> */}
            </Col>
            <Col lg="6" md="12">
            <div className="hero-images-container">
                <br></br>
                <br></br>
                <img
                  alt="..."
                  src={require("assets/img/solutions/mmwave.png")}
                ></img>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="6" md="12">
            <div className="hero-images-container">
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <img
                  alt="..."
                  src={require("assets/img/mmwave.png")}
                ></img>
              </div>
            </Col>
            <Col lg="6" md="12">
              <h4 className="title">Environmental occlusion</h4>
              <h5 >
                A significant challenge to sensors is that objects
                in the environment can often occlude line-of-sight
                vision to objects of interest. Walls, foliage, optically
                opaque glass and other objects block sensors
                based on optical technologies and ultimately
                limit the installation, placement and use of these
                sensors. Sensors that use radio frequency (RF) and other penetrating technologies have an advantage in
                that they can see through certain materials, opening
                up new ways to implement these sensors.
              </h5>
              <h5 >
                Having processing and decision-making at the
                edge also simplifies hardware and software design
                for building automation systems. With decisions at
                the sensor edge, users can realize simpler system
                designs and cost savings by minimizing data
                transfer, data storage and the need for a centralized
                system or person to make decisions.
              </h5>
              <Button
                className="btn-round mr-1"
                color="info"
                href="/products"
                size="lg"

              >
                View All Products
              </Button>
              {/* <Button
                className="btn-round"
                color="info"
                href=""
                outline
                size="lg"
              >
                Specifications
              </Button> */}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Home;

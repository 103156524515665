import DefaultFooter from "components/Footers/DefaultFooter";
import SolidNavbar from "components/Navbars/SolidNavbar";
import React from "react";
// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

// core components

function Rfid() {
  return (
  <>
    <SolidNavbar />
    <div className="section section-nucleo-icons">
    <h1 className="title text-center">RF ID SOLUTIONS</h1>
    <Container>
      <Row>
      <Col lg="6" md="12">
        <h4 className="title">RFID Heading</h4>
        <p>
          Digibloom Solutions offers a comprehensive Digital ID technology solution to the industry 
          Digibloom solutions offers solutions to the industry whether it’s product digitization and 
          authentication, item tracking, supply-chain optimization, or consumer encounters. 
          Avery Dennison’s comprehensive portfolio of digital ID technologies will fulfill your needs. 
          Whether it’s product digitization and authentication, item tracking, supply-chain management, 
          tracking and optimization, or richer consumer encounters, DSPL’s comprehensive portfolio of 
          digital ID technologies will fulfill the industry needs.
        </p>
        <p >
          We offer the industry’s most comprehensive portfolio of high-performing, high-quality RFID solutions. 
          Our RFID products and solutions can also be customized to individual requirements, broadening their 
          fields of application even further. We offer the industry’s most comprehensive portfolio of high-performing, 
          high-quality RFID solutions. Examples of our RFID solutions targeted towards various sectors 
        </p>
      </Col>
      <Col lg="6" md="12">
      <div className="hero-images-container">
        <br></br>
        <br></br>
        <img
          alt="..."
          src={require("assets/img/rfid/scm.png")}
        ></img>
        </div>
      </Col>
      </Row>
      <Row>
      </Row>
      <br></br>
      <br></br>
      <div className="section-story-overview">
      <Row>
        <Col md="12">
          <h3>
            Supply Chain Management
          </h3>
        </Col>
      </Row>
      <br></br>
      <Row>
          <Col md="12">
            <p>
              In today’s complex and dynamic business environment manufacturing enterprises have to change their business processes 
              to ensure customization of products, flexibility and responsiveness to the customer’s and business partner's requirements 
              in order to improve their competitiveness. Our comprehensive solution in process and efficiency improvement on supply 
              chain management starts from providing digital id to incoming materials irrespective of the form or nature of the incoming 
              goods and track it through the production process until finished goods and up to shipping and retail. 
            </p>
            <br></br>
            <h3>Retail Store & Customer Service Management </h3>
            <p>
              Imagine a consumer sees a pair of jeans they like in a retail store, and before buying them uses their mobile device to 
              scan the unique digital ID found on the product tag or label. They can now read reviews, find true-to-size ratings, 
              and access styling tips. After purchasing the jeans, the customer can register the product, gain loyalty points, hear 
              about other relevant products, and receive brand or retailer-specific content tailored to their personal preferences, 
              all with just one scan. Customers can also submit reviews or post to their Instagram account for additional incentives.
            </p>
          </Col>
        </Row>
      </div>
    </Container>
    </div>
    <DefaultFooter />
  </>
  );
}

export default Rfid;

import React from 'react';

// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Form
} from "reactstrap";
import axios from 'axios';

const JoinUsForm = () => {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");

  const onSubmit = (e) => {
    e.preventDefault()
    // alert(name)
    axios.post('https://3wcb0nbe89.execute-api.ap-south-1.amazonaws.com/dev/email/enquiry', {
      email: email,
      name: name,
      content: message,
    })
    .then((response) => {
      console.log(response);
      setName("")
      setEmail("")
      setMessage("")
      document.getElementById("x").reset();
      alert("Message Sent Successfully !!")
    }, (error) => {
      console.log(error);
      alert("Error !!")
    });
  }
  return(
    <React.Fragment>
      <div className="section section-contact-us text-center">
        <Container>
            <h2 className="title">Want to work with us?</h2>
            <p className="description">Your project is very important to us.</p>
            <Row>
              <Col className="text-center ml-auto mr-auto" lg="6" md="8">
                <Form  id="x">
                <InputGroup
                  className={

                    "input-lg" + (firstFocus ? " input-group-focus" : "")
                  }
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="now-ui-icons users_circle-08"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="First Name..."
                    type="text"
                    onFocus={() => setFirstFocus(true)}
                    onBlur={() => setFirstFocus(false)}
                    onChange={e => setName(e.target.value)}
                  ></Input>
                </InputGroup>
                <InputGroup
                  className={
                    "input-lg" + (lastFocus ? " input-group-focus" : "")
                  }
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="now-ui-icons ui-1_email-85"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email..."
                    type="text"
                    onFocus={() => setLastFocus(true)}
                    onBlur={() => setLastFocus(false)}
                    onChange={e => setEmail(e.target.value)}
                  ></Input>
                </InputGroup>
                <div className="textarea-container">
                  <Input
                    cols="80"
                    name="name"
                    placeholder="Type a message..."
                    rows="4"
                    type="textarea"
                    onChange={e => setMessage(e.target.value)}
                  ></Input>
                </div>
                <div className="send-button">
                  <Button
                    block
                    className="btn-round"
                    color="info"
                    href="#pablo"
                    onClick={onSubmit}
                    size="lg"
                  >
                    Send Message
                  </Button>
                </div>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
    </React.Fragment>
  )
}

export default JoinUsForm
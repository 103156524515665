import React from 'react';
import TeamMember from 'views/TeamMembersCard/TeamMember';
import dt from './team.json'


const TeamList = () => {
  return (
    dt.map((a) => {
      return <TeamMember 
        name={a.name}
        img={a.img}
        about={a.about}
        linkedin={a.linkedin}
      />
    })
  ); 
}

export default TeamList;
import DefaultFooter from "components/Footers/DefaultFooter";
import SolidNavbar from "components/Navbars/SolidNavbar";
import React from "react";
// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

// core components

function Iiot() {
  return (
  <>
    <SolidNavbar />
    <div className="section section-nucleo-icons">
    <h1 className="title text-center">IIOT Solutions</h1>
    <Container>
      <Row>
      <Col lg="6" md="12">
        <h4 className="title">IIOT Heading 1</h4>
        <p>
          Publicised worldwide as a part of the 4th industrial revolution, Industry 4.0 
          is a trend towards automation and data exchange in manufacturing technologies 
          and processes which include Cyber-Physical Systems (CPS), Internet of Things (IoT), 
          Industrial Internet of Things (IIOT), Cloud Computing, Cognitive Computing, 
          and Artificial Intelligence.
        </p>
        <p >
          Machine Data has become the centre of most business models and in industrial automation. 
          Companies that are born in the digital era have a higher understanding of this point as 
          compared to legacy companies.
        </p>
      </Col>
      <Col lg="6" md="12">
      <div className="hero-images-container">
        <br></br>
        <br></br>
        <img
          alt="..."
          src={require("assets/img/iiot/iiot2.png")}
        ></img>
        </div>
      </Col>
      </Row>
      <Row>
      <Col lg="6" md="12">
      <div className="hero-images-container">
        <br></br>
        <br></br>
        <img
          alt="..."
          src={require("assets/img/iiot/iiot.jpg")}
        ></img>
        </div>
      </Col>
      <Col lg="6" md="12">
        <h4 className="title">IIOT Heading 2</h4>
        <p >
          Enterprises like Facebook, Netflix, Amazon and Google began by investing largely into 
          these “Futuristic” technologies. They have designed their products in a manner wherein 
          they capture the user data in a far superior way than the traditional companies with 
          hybrid digitization.
        </p>
        <p >
          Similar trend also happens in the Industrial Automation side where data is captured all 
          through the product process and enables intelligent automation there by reducing the cost
          of production, make production more efficient and also making the products more reliable 
          and making product production scalable
        </p>
      </Col>
      </Row>
      <br></br>
      <br></br>
      <div className="section-story-overview">
      <Row>
        <Col md="12">
          <h3>
            Some useful information regarding industry 4.0
          </h3>
          <ul>
            <li>
              <p>
                It is estimated that by 2020, approximately 50 billion will be wirelessly connected to the internet.
              </p>
            </li>
            <li>
              <p>
                5G will be an important driver of Industry 4.0
              </p>
            </li>
            <li>
              <p>
                Big data and analytics will be of critical importance for the success of the Industry 4.0 concept
              </p>
            </li>
            <li>
              <p>
              A survey by McKinsey suggests that approximately 1.5 trillion USD of additional revenue would be generated 
              from on-demand mobile and data-driven services by 2030
              </p>
            </li> 
          </ul>
        </Col>
      </Row>
      <br></br>
      <Row>
          <Col md="12">
            <p>
              Digibloom solutions P Ltd enables technical education institutions like IIT , BITS, any University to learn cutting 
              dge technologies like IIoT, Augmented Reality - through industrial quality labs. The lab we built and handed over on 
              BOOT Model to BITS Pilani Hyderabad Campus delivers the following benefits to students
            </p>
            <br></br>
            <ol>
              <li>
                <p>
                  Industrial Automation - a lucid understanding of how to automate industrial processes.
                </p>
              </li>
              <li>
                <p>
                Digital Manufacturing - identifying optimal production methods to save energy, reduce waste
                </p>
              </li>
              <li>
                <p>
                Experiential learning - stronger learning outcomes and documented longer retention of subject matter
                </p>
              </li>
            </ol>
          </Col>
        </Row>
      </div>
    </Container>
    </div>
    <DefaultFooter />
  </>
  );
}

export default Iiot;

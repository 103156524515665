import React from "react";
// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";
import { ListGroup, ListGroupItem } from 'reactstrap';

// core components

function Home2() {
  return (
    <>
      <div className="section section-nucleo-icons">
        <Container>
          <Row>
            <Col lg="6" md="12">
              <h2 className="title">FEATURES</h2>
              <h5>
                <ListGroup flush>
                  <ListGroupItem>
                    Automated COVID- 19 Norm
                    adherance survailance on Social
                    distancing
                  </ListGroupItem>
                  <ListGroupItem>
                    Alarm if people don’t maintain
                    safe distance
                  </ListGroupItem>
                  <ListGroupItem>
                    Visual display on TV to display the
                    position of the defaulters and
                    alert him
                  </ListGroupItem>
                  <ListGroupItem>
                    Redeployable for future
                    survailace system
                  </ListGroupItem>
                  <ListGroupItem>
                    Auto body TEMPERATURE
                    MEASURMENT & ALARM & store
                  </ListGroupItem>
                  <ListGroupItem>
                    SELF CALIBRATION
                  </ListGroupItem>
                  <ListGroupItem>
                    Store the photograph of the
                    visitor with his details like Name,
                    Mobile Number & location with
                    date & time stamp details
                  </ListGroupItem>
                  <ListGroupItem>
                  Standard Report generation and contact tracing
                  </ListGroupItem>
                </ListGroup>
              </h5>
            </Col>
            <Col lg="6" md="12">
              <h2 className="title">APPLICATIONS</h2>
              <h5>
              <ListGroup flush>
                <ListGroupItem>OFFICES</ListGroupItem>
                <ListGroupItem>FACTORIES</ListGroupItem>
                <ListGroupItem>AIRPORTS</ListGroupItem>
                <ListGroupItem>SCHOOLS</ListGroupItem>
                <ListGroupItem>COLLEGES</ListGroupItem>
                <ListGroupItem>APARTMENTS</ListGroupItem>
                <ListGroupItem>HOTELS</ListGroupItem>
                <ListGroupItem>RAILWAY STATIONS</ListGroupItem>
                <ListGroupItem>BANKS</ListGroupItem>
                <ListGroupItem>MALL ENTRANCES</ListGroupItem>
                <ListGroupItem>BUS STATIONS</ListGroupItem>
              </ListGroup>
              </h5>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Home2;

import React from "react";
// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

// core components

function Home() {
  return (
    <>
      <div className="section section-nucleo-icons">
        <Container>
          <Row>
            <Col lg="6" md="12">
              <h2 className="title">NIRMAL-500</h2>
              <h5 className="description">
                Corona virus (COVID-19) is the new name for the
                disease being caused by the recent corona virus.
                A lot of information is being presented about how
                help prevent the virus from affecting you and
                your family. Perhaps the most important thing to
                know is that medical experts agree on is the use
                hand sanitizer to rid your hands of unwanted
                germs.
              </h5>
              <h5 className="description">
                We help our clients or build their success on us by working with us in the area of Industry
                Automation, IOT, Security &amp; surveillance , Digital Signage and professional services
              </h5>
              <Button
                className="btn-round mr-1"
                color="info"
                href="/products"
                size="lg"

              >
                View All Products
              </Button>
              <Button
                className="btn-round"
                color="info"
                href=""
                outline
                size="lg"
              >
                Specifications
              </Button>
            </Col>
            <Col lg="6" md="12">
            <div className="hero-images-container-3 ">
                <img
                  alt="..."
                  src={require("assets/img/products/nirmal-500.png")}
                ></img>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Home;

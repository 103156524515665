import React from 'react';
import { GoogleMap, withGoogleMap, withScriptjs } from 'react-google-maps'

const GMap = () => {
  return(
    <GoogleMap
      defaultCenter={{lat: 45, lng: -75}}
      defaultZoom={10}
    />
  )
}

const WrappedMap = withScriptjs(withGoogleMap(GMap))

const Map = (props) => {
  console.log(process.env.REACT_APP_GOOGLE_KEY)
  return(
    <React.Fragment>
      <div style={props.style}>
        <WrappedMap
          googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyAZ1ALN8YPiuI0lRTU7tnoos7P8btZ-HoQ`}
          loadingElement={<div style={{height: "100%"}} />}
          containerElement={<div style={{height: "100%"}} />}
          mapElement={<div style={{height: "100%"}} />}
        />
      </div>
    </React.Fragment>
  )
}

export default Map
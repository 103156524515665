import React from "react";

// reactstrap components
import { Container, Row, Col, Button } from "reactstrap";

// core components

function CompleteExamples() {
  return (
    <>
      <div className="section"> 
        <Container className="text-center">
          <Row className="justify-content-md-center">
            <h2 className="title">Digibloom Crowd Management Solution</h2>
            <Col lg="6" md="12">
              <p >
                In the crisis of COVID 19 contagion, the
                need of the hour is to maintain a SOCIAL
                DISTANCE of 1 meter. Maintaining social
                distance become very difficult when
                people are in public places like Mall,
                Theatres, event venues, Bus stand ,
                Railway stations , Airport etc. It is very
                unusual that PEOPLE follows the norm
                for social distancing and hence there is
                huge RISK of spread of the virus among
                the population.In the crisis of COVID 19 contagion, the
                need of the hour is to maintain a SOCIAL
                DISTANCE of 1 meter. Maintaining social
                distance become very difficult when
                people are in public places like Mall,
                Theatres, event venues, Bus stand ,
                Railway stations , Airport etc. It is very
                unusual that PEOPLE follows the norm
                for social distancing and hence there is
                huge RISK of spread of the virus among
                the population.
              </p>
            </Col>
            <Col lg="6" md="12">
              <p >
                DIGIBLOOM SOLUTIONS P LTD propose
                a solution or address solution to the
                PROBLEM by using a TECHNOLOGY
                called mmWave Radar for maintaining
                social distances when people are
                waiting in the line and temperature
                sensor to isolate people who shows
                symptoms of COVID-19.
              </p>
            </Col>
          </Row>
          <div className="hero-images-container-3">
            <img
              alt="..."
              src={require("assets/img/solutions/crowd-mgmt.png")}
            ></img>
          </div>
        </Container>
      </div>
      <Container>
        {/* <Button
          className="btn-round mr-1"
          color="info"
          href="about-us"
          role="button"
          size="lg"
        >
          Know More
        </Button> */}
      </Container>
    </>
  );
}

export default CompleteExamples;

import React from 'react';
import {
  Card, CardImg, CardText, CardBody,
  CardTitle, CardSubtitle, Button, Container, Col
} from 'reactstrap';
import Row from 'reactstrap/lib/Row';

const Example = (props) => {
  return (
    <Container>
      <div>
      <Row>
        <Col>
        <Card>
        <CardImg top width="100%" src={require("assets/img/landing-page/3.png")} alt="Card image cap" />
        <CardBody>
          <CardTitle tag="h5">Path Planning</CardTitle>
          {/* <CardSubtitle tag="h6" className="mb-2 text-muted">Card subtitle</CardSubtitle> */}
          <CardText>Use the advanced data set from the mmWave sensor to perform optimal path planning, even when there are hidden obstacles in the way.</CardText>
        </CardBody>
      </Card>
        </Col>
        <Col>
          <Card>
          <CardImg top width="100%" src={require("assets/img/landing-page/3.png")} alt="Card image cap" />
          <CardBody>
            <CardTitle tag="h5">Object Avoidance</CardTitle>
            {/* <CardSubtitle tag="h6" className="mb-2 text-muted">Card subtitle</CardSubtitle> */}
            <CardText>Use sophisticated object avoidance algorithms with the wide range of quality data provided from the mmWave module.</CardText>
          </CardBody>
        </Card>
          </Col>
          <Col>
          <Card>
          <CardImg top width="100%" src={require("assets/img/landing-page/3.png")} alt="Card image cap" />
          <CardBody>
            <CardTitle tag="h5">Difficult Environments</CardTitle>
            {/* <CardSubtitle tag="h6" className="mb-2 text-muted">Card subtitle</CardSubtitle> */}
            <CardText>Sense in difficult environments. Smoke and fog are invisible. Bright light or darkness is no problem for the mmWave sensor.</CardText>
          </CardBody>
        </Card>
          </Col>
      </Row>
    </div>
    <div>
        <Row>
          <Col>
          <Card>
          <CardImg top width="100%" src={require("assets/img/landing-page/3.png")} alt="Card image cap" />
          <CardBody>
            <CardTitle tag="h5">See Through Walls</CardTitle>
            {/* <CardSubtitle tag="h6" className="mb-2 text-muted">Card subtitle</CardSubtitle> */}
            <CardText>Give your projects x-ray vision. mmRadar waves can penetrate through walls enabling mmWave sensors to see what is on the other side of obstacles such as glass, acrylic, fibre and plaster.</CardText>
          </CardBody>
        </Card>
          </Col>
          <Col>
            <Card>
            <CardImg top width="100%" src={require("assets/img/landing-page/3.png")} alt="Card image cap" />
            <CardBody>
              <CardTitle tag="h5">110 Degrees of Vision</CardTitle>
              {/* <CardSubtitle tag="h6" className="mb-2 text-muted">Card subtitle</CardSubtitle> */}
              <CardText>Remove the limitation of only being able to detect objects directly ahead. The 110° field of view makes looking around easy.      </CardText>
            </CardBody>
          </Card>
            </Col>
            <Col>
            <Card>
            <CardImg top width="100%" src={require("assets/img/landing-page/3.png")} alt="Card image cap" />
            <CardBody>
              <CardTitle tag="h5">Detect & Track Multiple Objects Simultaneously</CardTitle>
              {/* <CardSubtitle tag="h6" className="mb-2 text-muted">Card subtitle</CardSubtitle> */}
              <CardText>Continuously measure distance, speed, direction, position, and size of objects. Track many objects at once as well as differentiating between people, and stationary objects.</CardText>
            </CardBody>
          </Card>
            </Col>
        </Row>
      </div>
    </Container>
  );
};

export default Example;
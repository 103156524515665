import React from "react";
// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

// core components

function NucleoIcons() {
  return (
  <>
    <div className="section ">
    <Container>
      <Row>
        <Col lg="6" md="12">
          <div className="hero-images-container-1">
          <img
            alt="..."
            src={require("assets/img/products/sanitizer.png")}
          ></img>
          </div>
        </Col>
        <Col lg="6" md="12">
          <h2 className="title">Sanitizer 2</h2>
          <h5 className="description">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor 
          incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation 
          ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit 
          in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
          </h5>
          <Button
          className="btn-round mr-1"
          color="info"
          href="/nucleo-icons"
          size="lg"
          target="_blank"
          >
          Buy Now
          </Button>
          <Button
          className="btn-round"
          color="info"
          href="https://nucleoapp.com/?ref=1712"
          outline
          size="lg"
          target="_blank"
          >
          Specifications
          </Button>
        </Col>
      </Row>
    </Container>
    </div>
  </>
  );
}

export default NucleoIcons;

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// styles for this kit
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.4.0";
import "assets/demo/demo.css?v=1.4.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.4.0";

//routes
import myroutes from './routes'
import Chatbot from './Chatbot'
// import Chatbot from "chatbot/chatbot";


//Function to generate routes
const getRoutes = (routes) => {
  return routes.map((prop, name) => {
    return(
      <Route 
        path={prop.path}
        render={(props) => {
          return <prop.component
            {...props}
          />
        }}
      />
    )
  })
}

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Switch>
        { getRoutes(myroutes) }
        <Redirect to="/index" />
        <Redirect from="/" to="/index" />
      </Switch>
    </Switch>
    <Chatbot />
  </BrowserRouter>,
  document.getElementById("root")
);